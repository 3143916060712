import { useAuthContext } from "../providers/AuthProvider";

export const Profile = props => {
    const [{ accessToken, profile }] = useAuthContext();
    return (
        <>
        <pre>{JSON.stringify(profile, " ", 4)}</pre>
        </>
    );
}

export default Profile;