export const App = {
    ApplicationName: 'Burza firem',
    ApplicationTitle: "Nabídka praxí a pracovních pozic"
};

export const Paths = {
    ApiAuthorizationClientConfigurationUrl: `_configuration/${App.ApplicationName}`,
    LoginCallback: "/oidc-callback",
    LogoutCallback: "/oidc-signout-callback",
    RenewCallback: "/oidc-silent-callback"
};