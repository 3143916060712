import React, { Component } from 'react';
import { Route, Routes } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { Profile } from './components/Profile';
import { AuthProvider } from "./providers/AuthProvider";
import SignInCallback from "./components/Auth/SignInCallback";
import SignOutCallback from "./components/Auth/SignOutCallback";
import SilentRenewCallback from "./components/Auth/SilentRenewCallback";
import Admin from './components/Admin/';
import NotFound from './components/General/NotFound';
import Unauthorized from './components/General/Unauthorized';
import List from "./components/Admin/Companies"
import Create from "./components/Admin/Companies/Create"
import CompanyDetail from "./components/Admin/Companies/Detail"
import Detail from "./components/Detail"
import { Paths } from "./configuration/main";

import './custom.css'

export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
      <AuthProvider>
        <Layout>
          <Routes>
            <Route path={Paths.LoginCallback} element={<SignInCallback />} />
            <Route path={Paths.LogoutCallback} element={<SignOutCallback />} />
            <Route path={Paths.RenewCallback} element={<SilentRenewCallback />} />
            <Route index path='/' element={<Home />} />
            <Route path='/:id' element={<Detail />} />
            <Route index path='/profile' element={<Profile />} />
            <Route path='/f4dg5gdf5' element={<Admin />} />
            <Route index path='/f4dg5gdf5/companies' element={<List />} />
            <Route path='/f4dg5gdf5/companies/create' element={<Create />} />
            <Route path='/f4dg5gdf5/companies/:id' element={<CompanyDetail />} />
            <Route path='/unauthorized' element={<Unauthorized />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Layout>
      </AuthProvider>
    );
  }
}
