import { useState } from 'react'
import { App } from "../configuration/main";
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, Nav, Spinner, Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import { useAuthContext } from "../providers/AuthProvider"
import './NavMenu.css'

export const NavMenu = props => {
    const [{ profile, accessToken, isUserLoading, userManager }] = useAuthContext();
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    return (
        <header>
            <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light fixed="top" color="light" >
                <Container>
                    <NavbarBrand tag={Link} to="/">{App.ApplicationTitle}</NavbarBrand>
                    <NavbarToggler onClick={toggle} />
                    <Collapse isOpen={isOpen} navbar>
                        <Nav className="me-auto" navbar>
                            {profile && profile.admin === "1"
                            ?
                            <NavItem>
                                <NavLink tag={Link} to="/f4dg5gdf5">Administrace</NavLink>
                            </NavItem>
                            :
                            null
                            }                     
                        </Nav>
                        <Nav className="ml-auto" navbar>
                        {
                          isUserLoading
                          ?
                          <Spinner size="sm" />
                          :
                          accessToken
                            ?
                            <>
                            <NavItem>
                              <NavLink tag={Link} to="/profile">{profile.preferred_username}</NavLink>
                            </NavItem>
                            <NavItem>
                              <Button color="danger" outline onClick={() => { userManager.signoutRedirect() }} >Odhlásit</Button>
                            </NavItem> 
                            </>   
                            :
                            <NavItem>
                              <Button color="success" outline onClick={() => { userManager.signinRedirect({redirectUrl: "/"}) }} >Přihlásit</Button>
                            </NavItem>  
                        }
                        </Nav>
                    </Collapse>
                </Container>
            </Navbar>
        </header>
    )
}

export default NavMenu;