import { useState, useEffect, useCallback } from 'react'
import { Spinner, Alert, ListGroup, ListGroupItem, Badge, Button, Input, Form } from 'reactstrap';
import axios from "axios"
import { Link } from 'react-router-dom'

export const Home = () => {
    const [data, setData] = useState(null)
    const [error, setError] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [branches, setBranches] = useState(null);
    const [errorActivities, setErrorActivities] = useState(false)
    const [isLoadingActivities, setIsLoadingActivities] = useState(false)
    const [activities, setActivities] = useState(null);
    const [selectedBranches, setSelectedBranches] = useState([]);
    const [isLoadingBranches, setIsLoadingBranches] = useState(false);
    const [errorBranches, setErrorBranches] = useState(false);
    const [selectedActivities, setSelectedActivities] = useState([]);
    const [name, setName] = useState("");
    const fetchBranches = useCallback(() => {
        setIsLoadingBranches(true);
        setErrorBranches(false);
        axios.get("/api/branches",{
            headers: {
                "Content-Type": "application/json"
            } 
        })
        .then(response => {
            setBranches(response.data);
        })
        .catch(error => {
            if (error.response) {
                setErrorBranches({status: error.response.status, text: error.response.statusText});
            }
            else
            {
                setErrorBranches({status: 0, text: "Neznámá chyba"});
            }         
            setBranches([]);
        });
        setIsLoadingBranches(false);
    },[]);
    const fetchActivities = useCallback(() => {
        setIsLoadingActivities(true);
        setErrorActivities(false);
        axios.get("/api/activities",{
            headers: {
                "Content-Type": "application/json"
            } 
        })
        .then(response => {
            setActivities(response.data);
        })
        .catch(error => {
            if (error.response) {
                setErrorActivities({status: error.response.status, text: error.response.statusText});
            }
            else
            {
                setErrorActivities({status: 0, text: "Neznámá chyba"});
            }         
            setActivities([]);
        })
        .then(() => {
            setIsLoadingActivities(false);
        })      
    },[]);
    const fetchCompanies = useCallback((selectedBranches, selectedActivities, name) => {
        setIsLoading(true);
        let headers = {
          "Content-Type": "application/json"
        }  
        axios.get("/api/companies", { headers: headers, params: {order: "name", branches: selectedBranches.join(","), activities: selectedActivities.join(","), name} })
        .then(response => { 
            setData(response.data.data);
            setError(null); 
        })
        .catch(error => {
          setData(null);
          if (error.response) {
              setError({status: error.response.status, text: error.response.statusText})
          } else {
              setError({status: 0, text: "??"});
          }
        })
        .then(()=>{
            setIsLoading(false)
        })
    },[]); 
    const toggleSelectedBranches = (id) => {
        let newSelectedBranches = [...selectedBranches];
        if (newSelectedBranches.includes(id))
        {
            var index = newSelectedBranches.indexOf(id);
            if (index !== -1) {
                newSelectedBranches.splice(index, 1);
            }
        }
        else
        {
            newSelectedBranches.push(id);
        }
        setSelectedBranches(newSelectedBranches);

    }  
    const toggleSelectedActivities = (id) => {
        let newSelectedActivities = [...selectedActivities];
        if (newSelectedActivities.includes(id))
        {
            var index = newSelectedActivities.indexOf(id);
            if (index !== -1) {
                newSelectedActivities.splice(index, 1);
            }
        }
        else
        {
            newSelectedActivities.push(id);
        }
        setSelectedActivities(newSelectedActivities);

    } 
    useEffect(() => {
        fetchCompanies(selectedBranches, selectedActivities, name);
    }, [fetchCompanies, selectedBranches, selectedActivities, name])

    useEffect(() => {
        fetchBranches();
        fetchActivities();
    },[fetchBranches, fetchActivities])

    return (
        <>
        {branches !== null && activities !== null
            ?
            <Form inline>
            <Input onChange={e => {setName(e.target.value)}} value={name} placeholder="Název nebo jeho část" />
            {branches.map((item, index) => (
                <Button key={index} className="m-1" style={
                    selectedBranches.includes(item.branchId)
                        ?
                        { backgroundColor: `rgb(${item.color.r},${item.color.g},${item.color.b})`, color: "white" }
                        :
                        { color: `rgb(${item.color.r},${item.color.g},${item.color.b})`, backgroundColor: "white" }
                } outline={!selectedBranches.includes(item.branchId)} onClick={e => { toggleSelectedBranches(item.branchId) }} size="sm">{item.name}</Button>
            ))}
            {activities.map((item, index) => (
                <Button key={index} className="m-1" outline={!selectedActivities.includes(item.activityId)} onClick={e => {toggleSelectedActivities(item.activityId)}} size="sm">{item.name}</Button>
            ))}
            </Form>
            :
            <div>Načítám obory</div>
            }
        {isLoading
        ?
                <div className="text-center p-3"><Spinner className="m-2"> </Spinner></div>
        :
            error 
            ?
            <Alert color="danger">Při získávání dat došlo k chybě.</Alert>
            :
                data
                ?
                    data.length > 0
                    ?
                    <ListGroup>
                    {data.map((item, index) => (
                        <ListGroupItem key={index} tag={Link} to={"/" + item.companyId}>{item.name}
                            {item.branches.map((item, index) => (<Badge key={ index } style={{backgroundColor: `rgb(${item.color.r},${item.color.g},${item.color.b})`, marginLeft: 5}}>{item.name.substr(0,3)}</Badge>))}
                        </ListGroupItem>
                    ))}
                    </ListGroup>
                    :
                    <Alert color="info" className="mt-2">Filtru neodpovídají žádné firmy.</Alert>
                :
                null
        }
        </>
    );
}

export default Home;
