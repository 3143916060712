import {useRequireAdmin} from "../Auth/useRequireAdmin"
import { Link } from 'react-router-dom'

const Admin = props => {
    useRequireAdmin();
    return (
        <p><Link to="companies">Firmy</Link></p>
    );
}

export default Admin;